import { render, staticRenderFns } from "./Lead.vue?vue&type=template&id=19f92142&scoped=true&lang=pug"
import script from "./Lead.vue?vue&type=script&lang=ts"
export * from "./Lead.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f92142",
  null
  
)

export default component.exports